<template>
  <div class="container" :class="{'container--backgrounded': $route.meta.isModal}">
    <CoverGrid />
    <CoverCredit />
    <MediaDigest />
    <p class="container__disclaimer">Die Webseite steht in keinerlei Verbindung mit einer politischen Partei, einem Herausgeber oder einem Publisher. Made in Germany with ♥ and a fragile internet connection. Inspiriert durch die reihenweise Bereicherung vieler Unionspolitiker während Millionen anderer Menschen unter den Folgen der Corona-Pandemie zu leiden haben, und <a href="https://www.clankriminalitaet.de/" rel="noopener" target="_blank">clankriminalität.de</a>. Fehlerhafte Fakten und Darstellungen werden dankbar via neuland@crimedrivenunion.de angenommen und korrigiert.</p>
    <Footer />
  </div>
</template>

<script>
export default {
  name: 'Index',
  components: {
    CoverGrid: () => import('@/components/CoverGrid.vue'),
    CoverCredit: () => import('@/components/CoverCredit.vue'),
    MediaDigest: () => import('@/components/MediaDigest.vue'),
    Footer: () => import('@/components/Footer.vue'),
  },
  metaInfo: {
    link: [
      { rel: 'canonical', href: 'https://crimedrivenunion.de' + (document.location.pathname === '/' ? '' : document.location.pathname) }
    ]
  }
}
</script>

<style scoped lang="sass">

.container
  transition: filter .1s linear

  &--backgrounded
    filter: blur(20px) brightness(0.3)

  &__disclaimer
    margin: 0 auto 10px
    max-width: 1000px
    font-size: 10px
    color: #ccc
    text-align: center

    a
      color: inherit
      text-decoration: none

</style>