<template>
  <div id="app" class="app">
    <router-view />
    <TheModals />
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
    TheModals: () => import('@/components/TheModals.vue'),
  },
}
</script>

<style lang="sass">

*
  box-sizing: border-box
  border: none
  padding: 0
  margin: 0

  &::before,
  &::after
    box-sizing: border-box

body
  background-color: #000
  color: #fff
  padding: 10px
  font: 14px Arial, sans-serif

  @media (min-aspect-ratio: 1/1)
    padding: 15px

::selection
  background-color: rgb(220, 50, 50)
  opacity: 1
  color: #fff

</style>
