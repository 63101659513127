import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import App from './App.vue'
import Index from './Index.vue'

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})
Vue.use(VueRouter)
Vue.config.productionTip = false

const router = new VueRouter({
  mode: 'history',
  routes: [
    { component: Index, path: '/', name: 'Index',  },
    { component: Index, path: '/impressum', name: 'Impressum', meta: { isModal: true } },
    { component: Index, path: '/datenschutz', name: 'Datenschutz', meta: { isModal: true } },
    { path: '*', redirect: '/' },
  ]
})

new Vue({
  router,
  render: (h) => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app')